import { CognitoJwtVerifier } from "aws-jwt-verify/cognito-verifier";
import config from "./config.js";
import { signOut } from "aws-amplify/auth";

class Authentication {
  constructor() {
    this.loginUrl = `${config.LOGIN_URL}/login?client_id=${config.aws_amplify.Auth.UserPoolWebClientId}&response_type=token&scope=email+openid+profile&redirect_uri=${window.location.protocol}//${window.location.host}`;
  }

  async login() {
    const urlParams = new URLSearchParams(window.location.hash.substring(1));
    const accessTokenFromQuery = urlParams.get("access_token");
    const idTokenFromQuery = urlParams.get("id_token");
    const refreshTokenFromQuery = urlParams.get("refresh_token");

    if (accessTokenFromQuery && idTokenFromQuery) {
      this.clearAllStorage();
      
      try {
        const accessTokenVerifier = this.createVerifier("access");
        const idTokenVerifier = this.createVerifier("id");
        
        await accessTokenVerifier.verify(accessTokenFromQuery);
        const idTokenPayload = await idTokenVerifier.verify(idTokenFromQuery);

        this.storeTokens(idTokenFromQuery, accessTokenFromQuery, refreshTokenFromQuery);
        localStorage.setItem("isAuthenticated", "true");

        window.history.replaceState({}, document.title, window.location.pathname);
        
        return idTokenPayload;
      } catch (error) {
        console.error("Error verifying tokens:", error);
        this.clearAllStorage();
        throw error;
      }
    }

    try {
      const accessToken = sessionStorage.getItem("accessToken");
      const idToken = sessionStorage.getItem("idToken");
      
      if (accessToken && idToken) {
        const accessTokenVerifier = this.createVerifier("access");
        const idTokenVerifier = this.createVerifier("id");
        
        await accessTokenVerifier.verify(accessToken);
        const idTokenPayload = await idTokenVerifier.verify(idToken);
        
        return idTokenPayload;
      }
      
      return null;
    } catch (error) {
      console.error("Error verifying stored tokens:", error);
      this.clearAllStorage();
      return null;
    }
  }

  async logout() {
    try {
      await signOut({ global: true });
      
      this.clearAllStorage();
      
      window.location.reload();
    } catch (error) {
      console.error("Logout error:", error);
      this.clearAllStorage();
      window.location.reload();
      throw error;
    }
  }

  clearAllStorage() {
    this.clearSessionStorage();
    
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("amplify-signin-with-hostedUI");
    
    const cognitoKeys = Object.keys(localStorage)
      .filter(key => key.includes("CognitoIdentityServiceProvider"));
    cognitoKeys.forEach(key => localStorage.removeItem(key));
  }

  clearSessionStorage() {
    sessionStorage.removeItem("idToken");
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
  }

  createVerifier(tokenUse) {
    return CognitoJwtVerifier.create({
      userPoolId: config.aws_amplify.Auth.UserPoolId,
      tokenUse,
      clientId: config.aws_amplify.Auth.UserPoolWebClientId,
    });
  }

  storeTokens(idToken, accessToken, refreshToken) {
    sessionStorage.setItem("idToken", idToken);
    sessionStorage.setItem("accessToken", accessToken);
    if (refreshToken) {
      sessionStorage.setItem("refreshToken", refreshToken);
    }
  }

  async isAdmin() {
    try {
      const idToken = sessionStorage.getItem("idToken");
      if (idToken) {
        const idTokenVerifier = this.createVerifier("id");
        const idTokenPayload = await idTokenVerifier.verify(idToken);
        return idTokenPayload["cognito:groups"]?.includes("Download-Portal-Admins") ?? false;
      }
      return false;
    } catch (error) {
      console.error("Error checking admin status:", error);
      this.clearAllStorage();
      throw error;
    }
  }
}

export default Authentication;